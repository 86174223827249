.analysis-box .analysis-box-title {
    font-weight: 700;
}

.analysis-box .analysis-box-header {
    display: flex;
    align-items: center;


}
.analysis-box-icon {
    justify-self: end;
}
.analysis-box-total {
    color: var(--colour-danger-900, #C10004);
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    display: inline-block;
    margin-right: 24px;
}
.app-analysis-container {
    margin-bottom: 64px;
}
.app-analysis-container:last-child {
    margin-bottom: 0px;
}

.analysis-box-date {

}
.analysis-box-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.analysis-box.cat-high {
    border-radius: var(--radius-radius-rounded, 24px);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
}
.analysis-box.cat-high .analysis-box-total{
    color: var(--colour-danger-900, #C10004);
}
.analysis-box.cat-high .analysis-box-title {
    color: var(--colour-danger-900, #C10004);
}

.analysis-box.cat-moderate {
    border-radius: var(--radius-radius-rounded, 24px);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
}
.analysis-box.cat-moderate .analysis-box-total{
    color: var(--colour-danger-900, #FCAB10);
}
.analysis-box.cat-moderate .analysis-box-title {
    color: var(--colour-danger-900, #FCAB10);
}

.analysis-box.cat-low {
    border-radius: var(--radius-radius-rounded, 24px);
    border: 1px solid var(--colour-plain-black-200, #CCCFCF);
    background: var(--brand-colours-primary-seafoam-white, #FFF);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
}
.analysis-box.cat-low .analysis-box-total{
    color: var(--colour-danger-900, #03A9F5);
}
.analysis-box.cat-low .analysis-box-title {
    color: var(--colour-danger-900, #03A9F5);
}
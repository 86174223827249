.automation-rule-page .page-content-header{
    background: transparent;
    box-shadow: unset;
}

.automation-rule-content {
    background: #fff;
    margin-bottom: 24px;
    border: 1px solid #E6E7E7;
    border-radius: 4px;

}

.page-content {
    display: flex;
    padding-top: 58px;
    width: 100%;
}

.automation-rule-page .MuiDataGrid-footerContainer{
    display: none !important;
}

.rules-datatable .Mui-checked{
    color: #008545 !important;
}

.rules-datatable .status-col .MuiTypography-root{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
}

.rules-datatable .status-col.active .MuiTypography-root{
    color: #008545;
}

.rules-datatable .status-col.inactive .MuiTypography-root{
    color: #CCCCCC;
}

.save-change-container .btn{
    margin-right: 15px;
}

.custom-disable{
    color: #CCCCCC;

}

@media screen and (max-width: 640px) {
    header.fixed {
        box-sizing: border-box;
        width: 100vw;
    }

    .inbox-page-details {
        background-color: #f0f0f000;
        padding: 0 16px 32px;
    }

    .page-content-body {
        box-sizing: border-box;
        border: none;

    }

    .inbox-page-details #detail-action{
        width: 100% !important;
    }
    .app-paper {
        padding: 16px;
        box-sizing: border-box;
        box-shadow: none!important;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
    }

    .app-paper .MuiBox-root{
        margin-bottom: 0;
    }

    .app-paper .MuiBox-root > .subject-title{
        margin-bottom: 8px;
    }

    .action-mobile .MuiButton-root{
        padding: 12px 16px;
    }

    /*Inbox Details Ation*/


    #action-mail {
        background: white;
        flex-direction: row;
        justify-content: space-between;
        background-color: #ffffff!important;
    }

    #action-mail .MuiList-root .MuiListItem-root{
        border-radius: 20px !important;
    }

    .dropdownlist-inbox-action{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0!important;
    }

    .dropdownlist-inbox-action > p {
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #909090;
    }

    .dropdownlist-inbox-action {
        align-items: baseline!important;
    }

    .dropdownlist-inbox-action:last-child .MuiButtonBase-root{
        background: transparent;
    }

    .list-item-dropdown {
        padding: 0!important;
    }

    .inbox-page .MuiTab-root {
        padding: 8px 16px;
        border-radius: 24px;
        min-height: 33px;

    }

    #lock-menu.MuiPopover-root .MuiPaper-root{
        width: unset;
    }

    /* Modal send mail */
    #modal-send-mail {
        z-index: 100000;
    }
    #modal-send-mail .MuiDialog-paper{
        width: 100vw;
        height: 100%;
        margin-top: 0;
    }

    #modal-send-mail .jodit-react-container{
        margin-bottom: 40px !important;
    }

    #modal-send-mail .tag-container p.MuiTypography-root{
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.analysis-risk-box.analysis-risk-box-high {
    border-radius: var(--radius-radius-minimal, 4px);
    border: 1px solid var(--colour-danger-300, #ED6969);
    background: var(--colour-danger-50, #FFEAED);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.10);
    margin-bottom: 16px;
    margin-top: 16px;
}

.analysis-risk-box.analysis-risk-box-moderate,
.analysis-risk-box.analysis-risk-box-medium {
    border-radius: var(--radius-radius-minimal, 4px);
    border: 1px solid #FCA910;
    background: #FFF8E1;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.10);
    margin-bottom: 16px;
    margin-top: 16px;
}
.analysis-risk-box.analysis-risk-box-high:last-child {
    margin-bottom: 0;
}
.analysis-risk-box svg[data-testid="ErrorIcon"] path {
    fill: white !important;
}

.analysis-risk-box .MuiChip-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-size: 20px;
}

.page-content{
    display: block;
}

.full-page {
    background: white;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.treemap-chart  .apexcharts-inner{
    transform: translate(0, 0)!important;
}
.treemap-chart {
    border-box: boxszing;
}


/*.treemap-chart .apexcharts-canvas,*/
/*.treemap-chart .apexcharts-canvas .apexcharts-svg{*/
/*    width: 100% !important;*/
/*}*/

.treemap-chart .apexcharts-canvas,
.treemap-chart .apexcharts-canvas .apexcharts-svg{
    width: 100% !important;
}

.treemap-chart .apexcharts-treemap-rect{
    width: 99.99%;
}
.alert-container{
    display: flex;
    align-items: center;
}

.alert-container.MuiAlert-filledError{
    min-height: 32px;
    height: 32px;
    background-color: #FFEAEA;
    color: #B00020;
    border-left: 4px solid #B20007;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 20px;
    padding: 8px;
    font-size: 14px;
}
.alert-container.MuiAlert-filledError .MuiAlert-icon {
    margin-right: 8px;
}
.alert-container.MuiAlert-filledInfo{
    min-height: 32px;
    background-color: #EDF1F5;
    color: #467599;
    border-left: 4px solid #467599;
    font-weight: 500;
    margin-bottom: 16px;
    line-height: 20px;
    padding: 0 8px;
    font-size: 14px;
}
.alert-container.MuiAlert-filledInfo .MuiAlert-icon {
    margin-right: 8px;
}
#chip-group .MuiChip-root.domain-default{
    background-color: #CCCCCC;
}

#chip-group .MuiChip-root:not(.domain-default){
    background-color: #E7EBF0;
}

#chip-group .MuiChip-root .MuiChip-label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #011111;
}

#chip-group .MuiChip-root:hover .MuiSvgIcon-root:not(:first-child){
    display: inline-block;
    position: absolute;
    right: 0;
    height: 100%;
}

#chip-group .MuiChip-root:hover .MuiSvgIcon-root[aria-label="Verify"],
#chip-group .MuiChip-root:hover .MuiSvgIcon-root[aria-label="Send test email"],
#chip-group .MuiChip-root:hover .MuiSvgIcon-root[aria-label="Edit"]{
    transform: translate(-88%, 0);
    background: #D7E2EE;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 5px;
}

#chip-group .MuiChip-root:hover .MuiSvgIcon-root[aria-label="Delete"]{
    transform: translate(0, 0);
    background: #D7E2EE;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 5px;
}

#chip-group .MuiChip-root .MuiSvgIcon-root:hover path{
    color: #467599;
}

#chip-group .MuiChip-root .MuiSvgIcon-root path{
    color: #909090;
}

#chip-group svg[data-testid="ErrorIcon"] path{
    fill: #193051;
}

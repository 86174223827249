.pagination {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #011111;
    float: right
}
.pagination select {
    font-family: 'Montserrat';
    font-weight: 500;
    border: 1px solid #CCCFCF;
    border-radius: 4px;
    color: #4C4C4C;
    height: 32px;
    background: #fff;
    margin-right: 10px;
    appearance: none;
    background: url('../../../../src/assets/images/arrow_black_drop_down.svg') no-repeat 80% 50%;
    padding-left: 10px;
}

.pagination-flex {
    display: flex;
    align-items: center;
    color: #212121;
    font-family: 'Montserrat';
    font-weight: 500;
}

.pagination-numbers {
    display: flex;
    padding: 0 14px 0 0;
    align-items: center;
    list-style-type: none;
}

.pagination-numbers li {
    margin-right: 7px;
    margin-left: 7px;
    cursor: pointer;
}

.pagination-numbers li .page-link {
    border: none;
    color: #4C4C4C;
    background: transparent;
    cursor: pointer;
}
.pagination-numbers li.active .page-link {
    cursor: default;
}
.pagination-numbers li .page-link:hover {
    color: #467599
}

.pagination-numbers li.active .page-link{
    border: 1px solid #467599;
    border-radius: 4px;
}

.pagination .first-page, .pagination .previous-page, .pagination .next-page, .pagination .last-page {
    position: relative;
    top: 2px
}

.pagination-numbers li.disabled {
    cursor: unset;
    color: rgba(0, 0, 0, 0.18);
    fill: rgba(0, 0, 0, 0.18);
    pointer-events: none;
}
.block-list-page .page-content-header {
    background: transparent;
    box-shadow: unset;
    padding: 24px 16px;
}

.block-list-page .page-content-header .page-content-title {
    display: flex;
    align-items: center;
    margin: unset;
}

.block-list-page .page-content-header .page-content-title .page-title {
    margin: unset;
}

.block-list-page .block-list-content {
    background: #fff;
    margin: 0 16px 40px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}

.block-list-page .block-list-content .block-list-search-bar {
    margin: 16px;
    padding: 16px 16px 12px;
    background: #EDEDED;
    display: flex;
}

.block-list-page .block-list-content .block-list-search-bar .block-list-reset-search-btn {
    margin-left: 8px;
}

.block-list-page .block-list-content .block-list-search-bar .block-list-reset-search-btn:hover {
    background: transparent;
}

.block-list-page .block-list-content .block-list-search-bar .block-list-search-dropdown .MuiFormControl-root {
    margin: 0 0 0 8px;
    background: #fff;
    border-radius: 4px;
}

.block-list-page .block-list-content .block-list-search-bar .block-list-search-dropdown .has-selected.MuiFormControl-root {
    background: #9FD8DB;
    border-color: #9FD8DB!important;
    color: #193051;
    font-weight: 600;
    border-radius: 4px;
    height: 40px;
}

.block-list-page .block-list-content .block-list-search-bar .block-list-search-dropdown .MuiFormControl-root .MuiInputBase-root {
    z-index: 2;
}

.block-list-page .block-list-content .block-list-table .actions-customize div:first-child .action-dropdown-list {
    height: 53px;
}

.block-list-page .block-list-content .block-list-search-bar .MuiOutlinedInput-root {
    height: 40px;
}

.block-list-page .block-list-content .block-list-search-bar .MuiOutlinedInput-root #attr_type-multiple-checkbox {
    padding-left: 117px;
    line-height: 40px;
}

.block-list-page .block-list-content .block-list-search-bar .MuiOutlinedInput-root #status-multiple-checkbox {
    padding-left: 116px;
    line-height: 40px;
}

.block-list-page .block-list-content .block-list-table .MuiDataGrid-columnHeaders:before {
    content: 'Actions';
    position: absolute;
    background: #fff;
    filter: drop-shadow(-4px 0px 20px rgba(0, 0, 0, 0.06));
    box-shadow: 0px 0px 3px 1px #eee;
    right: 0;
    width: 57px;
    padding: 0 8px;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 700;
    z-index: 2;
    max-height: 55px;
}

.block-list-page .block-list-content .block-list-table .block-list-toolbar {
    display: flex;
    height: 51px;
    justify-content: end;
    align-items: center;
}

.block-list-page .block-list-content .block-list-table .block-list-toolbar  > .MuiButtonBase-root {
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 20px;
}

.block-list-page .block-list-content .block-list-table .MuiDataGrid-cellContent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: break-spaces;
}

.block-list-page .block-list-content .block-list-table .actions-customize {
    position: absolute;
    top: 57px;
    right: 2px;
    width: 73px;
    background: #fff;
    box-shadow: 0px 0px 3px 1px #eee;
}
.block-list-page .MuiDataGrid-row {
    cursor: default;
}
#modal-edit-block-list {
    color: #212121;
    font-family: 'Montserrat';
}

#modal-edit-block-list .MuiDialog-paper{
    max-width: 700px;
}

#modal-edit-block-list #alert-dialog-title {
    padding: 16px 16px 16px 24px;
    border-bottom: 1px solid #E0E0E0;
}

#modal-edit-block-list .MuiDialogContent-root {
    padding: 24px;
}

#modal-edit-block-list .edit-block-list-content {
    max-width: 700px;
}

#modal-edit-block-list .edit-block-list-content .edit-block-list-title {
    font-weight: 600;
}

#modal-edit-block-list .edit-block-list-content .edit-block-list-info {
    margin-bottom: 32px;
}

#modal-edit-block-list .edit-block-list-content .edit-block-list-duration .MuiFormControl-root{
    margin: 4px 0;
    display: block;
}

#modal-edit-block-list .edit-block-list-content .edit-block-list-duration .MuiFormControl-root .MuiOutlinedInput-root{
    font-weight: 500;
}

#modal-edit-block-list .edit-block-list-content .edit-block-list-info .edit-block-list-value {
    display: block;
    /*height: 20px;*/
    background: #E7EBF0;
    border-radius: 24px;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding: 8px 8px 8px 16px;
}

#modal-edit-block-list #alert-dialog-action{
    padding: 11px 16px;
    border-top: 1px solid #E0E0E0;
}

#modal-delete-block-list .delete-block-list-value {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: break-spaces;
}

#modal-delete-block-list .MuiDialog-paper {
    max-height: 500px;
}

#modal-delete-block-list .value-block-list {
    max-width: 220px;
    word-break: break-word;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.block-list-table .MuiDataGrid-columnHeaderTitle {
    font-size: 12px
}

.RowStatus-expired {
    opacity: 40%;
}

.block-list-page .MuiDataGrid-root {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.RowValue {
    min-width: 200px;
    max-width: 650px;
}

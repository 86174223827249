

@media screen and (max-width: 640px) {
    /* start left nav menu */
    .left-content .left-nav.fixed{
        top: 0;
        z-index: 10001;
    }

    .left-content .left-nav.fixed.opened{
        left: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        top: 0;
        right: 0;
    }

    .right-content-opened{
        background: rgba(0, 0, 0, 0.53);
    }

    .button-section-group{
        width: 100%;
    }

    .left-nav-bottom{
        width: 100%;
        bottom: 0;
        justify-content: space-between;
    }

    .left-nav-bottom .company-name > .copyright-sign{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 15px;
        padding-right: 15px;
    }

    .left-nav-bottom .company-name .terms-of-use{
        font-size: 12px;
        text-align: right;
        color: #345C8B;
    }
    /* end left nav menu */
    .dropdown-list.icon-dropdown-header{
        padding: unset;
    }

    .dropdown-list.icon-dropdown-header.opened{
        padding: unset;
        background-color: unset !important;
    }

    .dropdown-list.icon-dropdown-header label .selected-title:after{
        content: unset;
        background: unset;
    }

    .dropdown-list.icon-dropdown-header .dropdown-content{
        left: unset;
        right: 0;
        top: 36px;
        min-width: 137px;
    }

    .button-section-group.fixed {
        width: 40px;
        overflow: hidden;
    }
    .statistic-section-group {
        padding: 16px;
    }
    .right-content {
        padding-left: 0!important;
        width: 100% !important;
    }
    .report-chart-group {
        padding: 0 40px 24px 24px;
        width: calc(100% - 48px);
        flex-wrap: wrap;
    }

    header .header-title .header-name{
        display: none;
    }

    .dashboard-search .search-box{
        flex-wrap: wrap;
    }

    .dashboard-search .searchbar {
        margin-top: 10px!important;
    }

    .statistic-section-group .statistic-section {
        min-height: 120px;
    }

    .statistic-section-group .statistic-section .title {
        font-size: 14px!important;
    }

    .report-chart-group .report-chart-wrapper.donut .report-chart{
        height: 320px!important;
    }

    .left-nav.fixed {
        left: -225px;
    }

    .left-nav.fixed.opened {
        left: 0;
        width: 225px;
    }

    .search-menu-right .MuiFormControl-root:first-child {
        margin-left: 0;
        margin-right: 16px;
    }

    .reset-btn {
        display: inline-block;
        height: 48px!important;
    }

    .pagination .totalRows, .pagination .rowPerPages {
        display: none;
    }

    .page-login {
        width: 100%;
        padding-left: 0;
        text-align: center;
    }

    .login-content .button-style:last-child {
        margin-top: 20px;
    }

    #terms-of-use #alert-dialog-title{
        font-size: 20px;
    }

    #terms-of-use .MuiPaper-root{
        margin-top: 90px;
        max-height: 87%;
    }

    .dropdown-menu-wrapper .dropdown-menu-body .MuiButtonBase-root{
        max-width: 100% !important;
        display: flex !important;
    }

    #left-menu{
        margin: 0 12px 4px 12px;
    }
}

/*  End CSS Inbox Page  */
@import "mobile/inbox.css";
@import "mobile/inboxDetail.css";

/*CSS Modal send mail*/
#modal-send-mail{
    z-index: 100;
}

#modal-send-mail .MuiDialog-paper{
    width: 1237px;
    max-width: 867px;
    height: auto;
    max-height: 90%;
}

#modal-send-mail .Mui-checked{
    color: #467599;
}

#modal-send-mail .MuiInputBase-input{
    height: 7px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    color: #212121;
    font-weight: 500;
}

#modal-send-mail .input-prefix .MuiInputBase-input {
    border: unset;
}

#modal-send-mail .MuiInputBase-input[disabled]{
    background: #EDEDED;
    cursor: not-allowed;
    -webkit-text-fill-color: #212121;
}

#modal-send-mail .MuiDialogContent-root,
#modal-delete-mail .MuiDialogContent-root,
#modal-login-azure .MuiDialogContent-root{
    padding: 10px 24px 0;
}

#modal-send-mail .MuiDialogActions-root{
    padding: 10px 20px 15px;
}

#modal-phish-clear .MuiDialogContent-root{
    padding: 0 24px;
}

#modal-send-mail #alert-dialog-title{
    align-items: center;
    color: #193051;
    border-bottom: 1px solid #E0E0E0;
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 24px;
}

#modal-send-mail #alert-dialog-action{
    border-top: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
}

#modal-delete-mail #alert-dialog-title,
#modal-login-azure #alert-dialog-title {
    display: flex;
    font-family: 'Montserrat';
    font-weight: 700;
    align-items: center;
    color: #212121;
}

#modal-delete-mail #alert-dialog-title > svg,
#modal-login-azure #alert-dialog-title > svg{
    font-size: 36px;
}

#modal-send-mail .form-body > div,
#modal-send-mail .form-body .MuiGrid-item{
    margin-bottom: 12px;
}

#modal-send-mail .label-input{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #212121;
    width: fit-content;
    margin-bottom: 4px;
}

#modal-send-mail .label-input-no-bottom{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #212121;
    width: fit-content;
}

#modal-send-mail .form-input{
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 4px;
}

#modal-send-mail #outline-tag-textfield .MuiInputBase-input{
    border: unset;
    height: 30px;
    width: 300px;
}

#modal-send-mail #outline-tag-textfield .MuiInputBase-root{
    min-height: 42px;
    height: 100%;
    align-items: start;
    flex-wrap: wrap;
    align-content: flex-start;
}

#modal-send-mail .tag-container .MuiBox-root{
    margin: 8px 8px 8px 0;
}

#modal-send-mail .tag-container .MuiBox-root:hover{
    background: #D7E2EE;
}

#modal-send-mail #outline-tag-textfield .tag-container svg.MuiSvgIcon-root path{
    fill: #909090;
}

#modal-send-mail #outline-tag-textfield .tag-container svg.MuiSvgIcon-root:hover path{
    fill: #212121;
}

#modal-send-mail .original-email{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #193051;
    background: #EDEDED;
    border-radius: 4px;
    width: fit-content;
    padding: 15px;
}

#modal-send-mail .original-email label{
    margin-left: 10px;
}


#modal-send-mail #alert-dialog-title {
    max-width: 100%;
}

/*CKEditor*/
.ck-editor__editable_inline {
    min-height: 200px;
}

.note-tooltip-content{
    min-width: fit-content!important;
    position: absolute;
    top: 5px;
    transform: translate(-50%, 0);
}

.note-modal.open .note-modal-content{
    margin: 200px auto;
}
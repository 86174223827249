
.text-priority{
    minWidth: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-percent{
    minWidth: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-number{
    minWidth: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.breadcrumb {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e1e4;
    width: calc(100% - 28px);
}

.breadcrumb a:hover {
    cursor: pointer;
}

.breadcrumb  a {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    color: #467599;
}

.breadcrumb .sub-link:last-child a {
    font-weight: 500;
    color: #011111;
}

.breadcrumb .go-back-icon.hidden {
    display: none;
}

.breadcrumb .sub-link{
    display: flex;
    align-items: center;
}

.breadcrumb .sub-link > svg{
    margin: 0 6px;
}
.threat-score-tooltip-wrapper .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    height: 32px;
}

.threat-score-tooltip-wrapper .threat-score-info {
    display: flex;
    border-top: 1px solid #E0E0E0;
    font-size: 14px;
    min-height: 61px;
    align-items: center;
    position: relative;
    line-height: 23px;
}


.button{
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
}
.button-style {
  margin-bottom: 2px;
}

.button-style:hover {
  background: #5883A3!important;
}

.button-style:hover .text-style {
  color: #fff!important;
}

.button.only-icon {
  text-align: center;
  justify-content: center;
}
.button.only-icon .image-style {
  margin: 0!important;
}
.button.clear-filter:hover .text-style{
  opacity: 0.7;
}

.left-nav-btn:not(.active) svg{
  margin-left: 4px;
}

.left-nav-btn:hover{
  background-color: #CFECF0 !important;
}

.button.left-nav-btn.active{
  background-color: #BDEAED !important;
  border-left: 4px solid #345C8B !important;
}

.left-nav-btn:hover .text-style{
  color: #7C8989 !important;
}

.button.left-nav-btn.active .text-style{
  color: #345C8B !important;
  font-weight: 700 !important;
}

.left-nav-btn:hover .btnNode path, .button.left-nav-btn.active .btnNode path{
  fill: #467599
}

.reset-btn {
  background-color: transparent;
}
.reset-btn:hover {
  cursor: pointer;
}

.pure-material-button-like {
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s;
  overflow: hidden;
}

.pure-material-button-like::-moz-focus-inner {
  border: none;
}

/* Overlay */
.pure-material-button-like::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transition: opacity 0.2s;
}

/* Ripple */
.pure-material-button-like::after {
  overflow: hidden;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px; /* Safari */
  height: 32px; /* Safari */
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.pure-material-button-like:hover,
.pure-material-button-like:focus {
  /*box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.12);*/
}

.pure-material-button-like.shadow-sm:hover {
  background-color: rgba(25, 48, 81, 0.04) !important;
}

.pure-material-button-like:hover::before {
  opacity: 0.08;
  background-color: rgba(25, 48, 81, 0.04);
}

.pure-material-button-like:focus::before {
  opacity: 0.24;
}

.pure-material-button-like:hover:focus::before {
  opacity: 0.3;
}

/* Active */
.pure-material-button-like:active {
  /*box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 1px rgba(0, 0, 0, 0.14), 0 1px 1px 1px rgba(0, 0, 0, 0.12);*/
}

.pure-material-button-like:active::after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

/* Disabled */
.pure-material-button-like:disabled {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
  box-shadow: none;
  cursor: initial;
}

.pure-material-button-like:disabled::before {
  opacity: 0;
}

.pure-material-button-like:disabled::after {
  opacity: 0;
}
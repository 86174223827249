.month-picker-container {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    width: 250px;
    padding: 0 10px 0 10px;
}

.month-picker-container .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
}

.month-picker-container .MuiPickersMonth-root .MuiPickersMonth-monthButton,
.month-picker-container .MuiPickersYear-root .MuiPickersYear-yearButton {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    width: 81px;
    height: 40px;
    margin: 0;
}

.month-picker-container .MuiPickersLayout-root .MuiDateCalendar-root .MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected,
.month-picker-container .MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected,
.month-picker-container .MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected:focus,
.month-picker-container .MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected:focus,
.month-picker-container .MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected:hover,
.month-picker-container .MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected:hover {
    background-color: #0E4F83;
    border-radius: 4px;
    font-weight: 600;
    width: 81px;
    height: 40px;
}

.month-picker-container .MuiPickersLayout-root .MuiDateCalendar-root .Mui-disabled {
    color: #99A0A0;
    font-weight: 400;
}

.month-picker-container .MuiPickersLayout-root .MuiDateCalendar-root .MuiSvgIcon-root {
    color: #193051;
}

.month-picker-container .MuiPickersMonth-root .MuiPickersMonth-monthButton:hover,
.month-picker-container .MuiPickersYear-root .MuiPickersYear-yearButton:hover,
.month-picker-container .MuiPickersMonth-root .MuiPickersMonth-monthButton:focus,
.month-picker-container .MuiPickersYear-root .MuiPickersYear-yearButton:focus{
    border-radius: 4px;
    width: 81px;
    height: 40px;
}

.month-picker-container .MuiPickersLayout-root .MuiPickersLayout-toolbar,
.month-picker-container .MuiPickersLayout-root .MuiDialogActions-root {
    display: none !important;
}

.month-picker-container .MuiPickersLayout-root .MuiDatePickerToolbar-title {
    font-weight: 500;
    font-size: 28px;
}

.month-picker-container .MuiPickersLayout-root .MuiMonthCalendar-root {
    padding: 0 0 20px 0;
    width: 243px;
}

.month-picker-container .MuiPickersLayout-root .MuiYearCalendar-root {
    padding: 0;
}

.month-picker-container .MuiPickersLayout-root .MuiMonthCalendar-root:before {
    content: '';
    position: absolute;
    height: 1px;
    top: -1px;
    width: 246px;
    left: 2px;
    border-bottom: 1px solid #CCCFCF;
}

.month-picker-container .MuiMonthCalendar-root:after {
    content: '';
    position: absolute;
    height: 1px;
    top: 178px;
    width: 246px;
    left: 2px;
    border-bottom: 1px solid #CCCFCF;
}

.month-picker-container .month-picker-actions-dialog {
    padding: 8px 10px 10px 10px;
    text-align: end;
    background-color: #fff;
}

.month-picker-container .month-picker-actions-dialog .month-picker-cancel {
    color: #0E4F83;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
}

.month-picker-container .month-picker-actions-dialog .month-picker-apply {
    color: #0E4F83;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
}

.month-picker-container .MuiPickersLayout-root .MuiYearCalendar-root {
    border-bottom: 1px solid #CCCFCF;
    border-top: 1px solid #CCCFCF;
    max-height: 302px;
    width: 246px;
}
.month-picker-container .MuiPickersLayout-root .MuiYearCalendar-root::-webkit-scrollbar {
    width: 3px;
    align-items: center;
    padding: 62px 0px 51px;
    width: 3px;
}

.month-picker-container .MuiPickersLayout-root .MuiYearCalendar-root::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 4px;
    height: 138px;
}
.month-picker-container .MuiYearCalendar-root{
    margin-left: 2px;
}
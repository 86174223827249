.dropdown-list {
    position: relative;
    margin-right: 16px;
    box-sizing: border-box;
    border: 1px solid #CCCFCF;
    border-radius: 4px;
}
.dropdown-list label{
    color: #8C8C8C;
    font-size: 14px;
    margin-left: 0;
}
.dropdown-list label .selected-title {
    cursor: pointer;
    color: #011111;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-list.actions-dropdown-inbox label .selected-title:after {
    background: url('../../../assets/images/arrow_blue_drop_down.svg') no-repeat center;
    content: '';
    width: 11px;
    height: 8px;
    display: inline-block;
    margin-left: 8px;
    position: relative;
    top: -1px;
}

.icon-dropdown-header .dropdown-content{
    top: 28px;
    left: unset;
    right: 0;
    min-width: 115px;
    border: unset;
}

.icon-dropdown-header .item ul li {
    padding: 10px 12px ;
}

.dropdown-list.icon-dropdown-header label .selected-title:after{
    background: url('../../../assets/images/arrow_white_drop_down.svg') no-repeat center;
    content: '';
    width: 11px;
    height: 8px;
    display: inline-block;
    margin-left: 8px;
    position: relative;
    top: -1px;
}

.dropdown-list.disabled label .selected-title:after {
    background: url('../../../assets/images/arrow_dropdown-grey.svg') no-repeat center;
    content: '';
    width: 11px;
    height: 8px;
    display: inline-block;
    margin-left: 8px;
    position: relative;
    top: -1px;
}

.dropdown-list.blue-dropdown label .selected-title:after {
    background: url('../../../assets/images/arrow_drop_down.svg');
    width: 12px;
    height: 6px;
}

.dropdown-list.blue-dropdown label .selected-title {
    color: #FFFFFF;
    font-family: "Righteous";
    font-size: 14px;
}

.dropdown-list.blue-dropdown .dropdown-content {
    min-width: 390px;
}

.dropdown-list.actions-dropdown{
    border: 1px solid #467599;
    border-radius: 4px;
    width: 100%;
    background-color: #345C8B !important;
}

.dropdown-list.actions-dropdown .selected-title{
    line-height: 40px;
    color: #fff;
    font-family: "Montserrat";
    font-weight: 600;
}

.dropdown-list.actions-dropdown .dropdown-content {
    min-width: inherit;
    width: 100%;
    top: 40px;
}
.dropdown-list.actions-dropdown .selected-title:after {
    display: none;
}
.dropdown-list.actions-dropdown .selected-title .arrow-action {
    background: url('../../../assets/images/arrow_white_drop_down.svg') no-repeat center;
    width: 40px;
    height: 40px;
    border-left: 1px solid #345C8B;
    position: absolute;
    margin: 0;
    right: 0;
    top: 0px;
}

.dropdown-list label.opened .selected-title:after{
    transform: rotate(180deg);
}

.dropdown-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.dropdown-content ul li {
    cursor: pointer;
    margin: 0;
    color: #011111;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0;
}

.dropdown-content ul li.disabled,
.dropdown-content ul li.disabled:hover{
    color: #CCCCCC;
    opacity: 0.6;
}

.dropdown-content ul li.hidden {
    display: none;
}

.dropdown-content ul li:hover {
    background: #E7EBF0;
    color: #212121;
    /*border-radius: 4px;*/
}

.dropdown-content {
    position: absolute;
    left: 0;
    top: 38px;
    z-index: 2;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #E6E7E7;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    min-width: 120px;
}

.item-selected{
    background: #5883A3;
    color: #fff;
}

.actions-dropdown.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: none;
}

.actions-dropdown label.opened {
    background: #6583A7;
}
.actions-dropdown label.opened .selected-title {
    color: #fff;
}
.dropdown-list.actions-dropdown label.opened .selected-title .arrow-action {
    background: url('../../../assets/images/arrow_white_drop_down.svg') no-repeat center;
    border-color: #6583A7;
    transform: rotate(180deg);
}

.action-dropdown-list .dropdown-list.actions-dropdown .dropdown-content {
    width: 202px;
    right: -2px;
    left: auto;
}

.dropdown-list.blue-dropdown {
    background-color: transparent!important;
    border: none;
}

.actions-dropdown-inbox button{
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.actions-dropdown-inbox .selected-title{
    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    justify-content: space-between;
    color: #345C8B;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 0 14px;
}

.dropdown-list.icon-dropdown-header{
    padding: 4px 8px;
    border: unset;
}

.dropdown-list.icon-dropdown-header.opened{
    padding: 4px 8px;
    background-color: #345C8B !important;
    border-radius: 4px;
}

.dropdown-list.icon-dropdown-header label .selected-title{
    color: #fff ;
    font-weight: 500;
}

.dropdown-list.actions-dropdown-inbox.opened {
    background-color: rgba(52, 92, 139, 0.12)!important;
}

.dropdown-list.actions-dropdown-inbox:hover{
    background-color: rgba(52, 92, 139, 0.12)!important;
}

.dropdown-list.actions-dropdown-inbox.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.dropdown-content.actions-dropdown-inbox-for-action {
    max-width: 226px;
    width: 226px;
}

.is_archived #actions-dropdown-inbox{
    width: unset;
}

/*li:not(.menu-group-1) + .menu-group-1 {*/
/*    border-top: 1px solid #CCCCCC;*/
/*}*/

/*.menu-group-1 + .menu-group-1 {*/
/*    border-bottom: 1px solid #ccc;*/
/*}*/

/*.menu-group-1:only-child {*/
/*    border: none !important;*/
/*}*/

.menu-sep-line {
    padding: 0 !important;
    background: #E0E0E0;
    height: 1px;
}

.setting-page .status-setting .MuiTypography-root{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
}

.setting-page .status-setting{
    margin-left: 0;
}

.setting-page .status-setting.enabled .MuiTypography-root{
    color: #008545;
}

.setting-page .status-setting.disabled .MuiTypography-root{
    color: #CCCCCC;
}

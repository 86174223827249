.line-chart .apexcharts-xaxis {
    display: none
}

.line-chart  .apexcharts-yaxis {
    display: none
}
.line-chart  .apexcharts-xcrosshairs,
.line-chart  .apexcharts-ycrosshairs {
    display: none;
}

.tooltip-marker {
    background: #011111;
    opacity: 0.9;
    height: 24px;
    min-width: 52px;
    border-radius: 2px;
    padding: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}


.tooltip-title {
    padding: 0 6px;
    font-size: 14px;
    font-weight: 500;
    color: #E6E7E7;
}
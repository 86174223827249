.tree-dropdown-menu-header .tree-input input {
    padding-left: 16px;
}

.MuiTreeView-root.tree-view-dropdown::-webkit-scrollbar {
    width: 4px;
    align-items: center;
    /*padding: 62px 0px 51px;*/
    height: 4px;
}

.MuiTreeView-root.tree-view-dropdown::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 4px;
    height: 138px;
}
.tree-view-dropdown .MuiTreeItem-root .Mui-selected{
    background-color: #EDEDED;
}

.tree-view-dropdown .MuiTreeItem-root .Mui-focused{
    /*background-color: #E7EBF0;*/
    background-color: unset;
}
.tree-dropdown-menu-header .tree-input input::placeholder { /* Microsoft Edge */
    color: #666666;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
}
.tree-dropdown-menu-header{
    border-bottom: 1px solid #E0E0E0;
}
#tree-customized-button{
    font-family: 'Righteous';
    font-size: 14px;
    font-weight: 400;
}

.tree-customized-button-label {
    font-family: 'Righteous';
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 700px;
}

.tree-tooltip-label svg{
    display: none;
}

.tree-limit-with-label .tree-label-text{
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header-viewer-wrapper {
    height: auto;
    font-family: "Montserrat";
    font-size: 14px;
    color: #011111;
}

#modal-header-view-detail .MuiDialog-paper {
    border-radius: 4px;
    font-family: 'Montserrat';
    min-width: 1242px;
    min-height: 800px;
}

#modal-header-view-detail #alert-dialog-title {
    color: #193051;
    padding: 16px 16px 16px 24px;
    font-family: "Righteous";
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    border-bottom: 1px solid #E0E0E0;
}

.header-viewer-wrapper .title{
    font-size: 20px;
    font-weight: 700;
}

.header-viewer-wrapper .sub-title {
    font-size: 16px;
    font-weight: 700;


}

.header-viewer-wrapper .delivery-information-wrapper {
    margin-top: 24px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    margin-bottom: 40px;
}

.header-viewer-wrapper .delivery-information-wrapper .delivery-information-content {
    display: flex;
    padding: 16px;
}

.header-viewer-wrapper .delivery-information-wrapper .delivery-information-content .information-table {
    margin-right: 24px;
    min-width: 300px;
    border-bottom: 1px solid #E0E0E0;
}

.header-viewer-wrapper .delivery-information-wrapper .delivery-information-content .information-table .table-header {
    background-color: #F5F5F5;
    min-height: 40px;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
}

.header-viewer-wrapper .delivery-information-wrapper .delivery-information-content .information-table .table-row {
    min-width: 300px;
    min-height: 40px;
    padding: 0 16px 0 16px;
    border-top: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
}

.header-viewer-wrapper .delivery-information-wrapper .app-label-icon {
    margin-left: 16px;
}

.header-viewer-wrapper .email-header-viewer-content {
    margin-top: 24px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
}

.header-viewer-wrapper .search-filter {
    width: 400px !important;
}

.header-viewer-wrapper .search-filter .text-input-style {
    width: 400px !important;
    padding-left: 35px !important;
}

.header-viewer-wrapper .search-filter .text-input-style:focus {
    border: 1px solid #666666;
}

.header-viewer-wrapper .search-filter .icon-search {
    left: 10px;
}
.verify-email-page-dialog .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    max-width: 700px;
    border-radius: 0;
}
.verify-email-page-dialog .verify-email-title{
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #193051;
}

.verify-email-page-dialog .verify-email-description {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #212121;
}

.verify-email-page-dialog .verify-email-input-label{

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #212121;

}

.verify-email-page-dialog .btn-verify-email-address-contained {
    font-family: 'Metropolis';
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    background: #345C8B;
}

.verify-email-page-dialog .btn-verify-email-address-contained:hover {
    background-color: #193051;
}

.verify-email-page-dialog .input-field{
    width: 400px;
}
.verify-email-page-dialog .verify-email-message{
    display: flex;
    height: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    /*color: #008545;*/
    align-items: center;
    margin: 18px 0;
}
.verify-email-page-dialog .verify-email-message .success{
    color: #008545;
}
.verify-email-page-dialog .verify-email-message .fail{
    margin-left: 5px;
    color: #C80000;
}
.verify-email-page-dialog .verify-email-loading{
    max-width: 390px;
}
.verify-email-page-dialog .verify-email-loading .message{
margin-top: 4px;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    color: #212121;
}

.verify-email-page-dialog .resend-verify-email {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #212121;
    margin-right: 8px;
}

.verify-email-page-dialog .link-verify-email {
    color: #467599;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    line-height: 23px;
    text-decoration: underline;
    cursor: pointer;
}

.verify-email-page-dialog .MuiInputBase-root.Mui-disabled{
    background-color: #EDEDED;
}
.search-filter {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 240px;

}

.search-filter input:focus {
    background: #E0E0E0;
    border: 1px solid #666666;
}

.search-filter .icon-search{
    position: absolute;
    right: 10px;
    width: 25px;
    height: 16px;
}

.search-filter .icon-search path{
    fill: rgba(0, 0, 0, 0.6);
}

.search-filter .text-input-style{
    /*padding-left: 40px !important;*/
    width: 240px!important;
    font-size: 14px;
    border: 1px solid #E0E0E0;
}

.search-filter.without-icon .text-input-style{
    padding: 0 16px !important;
}

.delivery-container{
    padding: 24px;
    margin: 24px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
}

.delivery-container .verifier-group,
.delivery-container .verifier-group .delivery-group-status .delivery-group-status-alignment {
    display: flex;
    align-items: center;
}

.delivery-container .verifier-group .delivery-group-status .delivery-group-status-authenticated {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.delivery-container .verifier-group .delivery-group-status{
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.90);
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 16px;
}

.delivery-container .verifier-group .title{
    color: #011111;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.delivery-container .verifier-group .verifier-value{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    margin-left: 8px;
    border-radius: 24px;
    background: #E7EBF0;
    color: #011111;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.delivery-container .record-container:not(.error){
    margin-top: 24px;
}

.delivery-container .record-container .dmarc-domain{
    color: #467599;
    font-style: italic;
    font-weight: 700;
}

.delivery-container .record-container .title{
    color: #011111;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.delivery-container .record-container .desc-content{
    border-radius: 4px;
    display: flex;
    padding: 16px;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-start;
    background: #F2F3F3;
}

.delivery-container .record-container .desc-content.pass{
    background: rgba(0, 133, 69, 0.1);
}

.delivery-container .record-container .desc-content.fail{
    background: rgba(200, 0, 0, 0.10);
}

.delivery-container .record-container .desc-content.error{
    background: rgba(242, 145, 0, 0.10);
}

.delivery-container .record-container .record-desc{
    color: #011111;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    word-break: break-word;
}

.delivery-container .record-container .record-status{
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.delivery-container .verifier-group .record-status {
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.delivery-container .record-container .record-status.pass,
.delivery-container .verifier-group .record-status.pass {
    color: #008545;
}

.delivery-container .record-container .record-status.fail,
.delivery-container .verifier-group .record-status.fail {
    color: #C80000;
}

.delivery-container .record-container .record-status.error,
.delivery-container .verifier-group .record-status.error {
    color: #F29100;
}

.delivery-container .record-container .record-status span,
.delivery-container .verifier-group .record-status span {
    margin-left: 8px;
    text-transform: capitalize;
}

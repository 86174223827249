.stacked-column-chart .apexcharts-legend-series {
    margin: 24px 10px 2px!important;
}
.stacked-column-chart .apexcharts-legend-series span{
    color: #808888!important;
}





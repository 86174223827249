.table-dropdown {
    font-family: 'Montserrat';

}

.table-dropdown .action-dropdown-list {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    width: 98px;
    cursor: pointer;
    height: 20px;
    box-sizing: border-box;

}

.table-dropdown .action-dropdown-list:hover {

}

.actions-rules {
    padding: 0;
}

.actions-rules ul {
    padding-left: 0;
}

.actions-rules ul li{
    margin: 0;
    padding: 10px 12px;
    cursor: pointer;
    list-style-type: none;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.actions-rules ul li:hover, .actions-rules ul li.active {
    background: #E7EBF0;
    color: #212121;
}

.table-dropdown .action-dropdown-list.priority .text-style,
.table-dropdown .action-dropdown-list.priority .arrow-down {
    background: inherit!important;
}

.table-dropdown .action-dropdown-list.category, .table-dropdown .action-dropdown-list.status {
    box-shadow: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.table-dropdown .action-dropdown-list.category{
    justify-content: flex-start;
}


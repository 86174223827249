.block-list-activity-logs-page .page-content-header {
    background: transparent;
    box-shadow: unset;
    padding: 24px 16px;
}

.block-list-activity-logs-page .activity-logs-content {
    background: #fff;
    margin: 0 16px 40px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
    border-radius: 4px;

}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar {
    margin: 16px;
    padding: 16px 16px 12px;
    background: #EDEDED;
    display: flex;
}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar .activity-logs-search-dropdown .MuiFormControl-root {
    margin: 0 0 0 8px;
    background: #fff;
    border-radius: 4px;
}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar .activity-logs-search-dropdown .has-selected.MuiFormControl-root {
    background: #9FD8DB;
    border-color: #9FD8DB!important;
    color: #193051;
    font-weight: 600;
    border-radius: 4px;
    height: 40px;
}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar .activity-logs-search-dropdown .MuiFormControl-root .MuiInputBase-root {
    z-index: 2;
}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar .activity-logs-reset-search-btn {
    margin-left: 8px;
}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar .activity-logs-reset-search-btn:hover {
    background: transparent;
}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar .MuiOutlinedInput-root {
    height: 40px;
}

.block-list-activity-logs-page .activity-logs-content .activity-logs-search-bar .MuiOutlinedInput-root #action_type-multiple-checkbox {
    padding-left: 100px;
    line-height: 40px;
}

.activity-logs-table .MuiDataGrid-columnHeaderTitle {
    font-size: 12px
}

.activity-logs-table .MuiDataGrid-root {
    border: none;
    border-radius: 0;
}

.activity-logs-table .MuiDataGrid-row {
    cursor: default;
}
.MuiSnackbar-root .MuiAlert-root{
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.MuiSnackbar-root .MuiAlert-root .MuiAlert-icon{
    color: #fff;
}

.MuiSnackbar-root .MuiAlert-standardSuccess{
    background: #008545;
}

.MuiSnackbar-root .MuiAlert-standardError {
    background: #ED1C24;
}

.MuiSnackbar-root .MuiAlert-message {
    margin-right: 6px;
}

.MuiAlert-action{
    padding: 0 10px !important;
    cursor: pointer;
}

.icon-close .MuiSvgIcon-root{
    font-size: 14px;
}

@media screen and (max-width: 1280px) {
    .statistic-section-group {
        flex-wrap: wrap;
    }
    .statistic-section {
        width: calc(50% - 16px)!important;
        margin-bottom: 16px;
    }

    .report-chart-group .report-chart-wrapper {
        width: 100%!important;
        margin-right: 0!important;
        margin-bottom: 20px;
    }
    .report-chart-group {
        flex-wrap: wrap;
    }

    .dashboard-search .search-box{
        flex-wrap: wrap;
    }

    .dashboard-search .searchbar {
        margin-top: 10px!important;
    }
}

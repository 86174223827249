.page-login{
    padding: 60px;
    font-family: "Montserrat";
}
.login-content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    width: 100%;
}
.login-content .welcom p{
    display: block;
    padding-left: 3px;
    font-family: "Montserrat";
    padding-right: 3px;
}
.login-content .button-style {
    margin: 20px auto;
    padding: 5px;
    display: block;
    background: #345C8B;
    border-radius: 4px;
    font-weight: 600;
}

.login-content .login-text{
    font-family: "Righteous";
}

.new-base-domain-page-dialog .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    max-width: 589px;
    border-radius: 0;
}
.new-base-domain-page-dialog .new-base-domain-title{
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #193051;
}

.new-base-domain-page-dialog .new-email-sender-description {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #212121;
}

.new-base-domain-page-dialog .new-base-domain-input-label{

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #212121;

}
.new-base-domain-page-dialog .btn-new-base-domain-outlined {
    font-family: 'Metropolis';
    font-weight: 600;
    font-size: 14px;
    color: #345C8B;
}

.new-base-domain-page-dialog .btn-new-email-address-contained {
    font-family: 'Metropolis';
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    background: #345C8B;
}
.new-base-domain-page-dialog .input-field{
    width: 400px;
}
.new-base-domain-page-dialog .send-mail-base-domain{
    display: flex;
    height: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    /*color: #008545;*/
    align-items: center;
    margin: 18px 0;
}
.new-base-domain-page-dialog .send-mail-base-domain .success{
    color: #008545;
}
.new-base-domain-page-dialog .send-mail-base-domain .fail{
    margin-left: 5px;
    color: #C80000;
}
.new-base-domain-page-dialog .loading-base-domain{
    max-width: 390px;
}
.new-base-domain-page-dialog .loading-base-domain .message{
margin-top: 4px;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    color: #212121;
}

.new-base-domain-page-dialog .MuiInputBase-root.Mui-disabled{
    background-color: #EDEDED;
}
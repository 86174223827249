.button-section-group {
    z-index: 9999;
    width: 216px;
    height: 100%;
}

.button-section-group .nav-bg{
    padding: 5px;
}

.button-section-group .MuiAccordion-root .nav-bg{
    padding: 0;
}

.left-nav.fixed {
    position: fixed;
    width: 225px;
    top: 58px;
    left: 0;
    height: 100%;
    background-color: #FFFFFF;
    transition: all 0.5s;
    z-index: 100;
}

.left-nav.fixed.opened {
    left: -225px;

}


.left-nav .arrow-left-nav, .left-nav .arrow-right-nav {
    position: absolute;
    top: 18px;
    right: 8px;
}
.left-nav .arrow-right-nav {
    right: 8px;
}

.left-nav-content {
    display: flex;
    align-items: center;
    margin: 24px 16px 24px 12px;
    width: 200px;
}

.left-nav-content .text-style {
    transition: all .5s;
    min-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: break-spaces;
}
.right-content   {
    padding-left: 225px;
    transition: all 0.5s;
}
.right-content-opened .right-content {
    padding-left: 0;
    width: 100%;
}

.left-nav .left-nav-btn {
    position: relative;
}

.left-nav.fixed .button-section-group .text-style{
    font-weight: 500;
}


.btn-tooltip .left-nav-btn:before {
    content: '';
    width: 64px;
    height: 40px;
    position: absolute;
    left: -12px;
    z-index: 999;
    cursor: pointer;
}
.left-nav-bottom {
    border-top: 1px solid #E6E7E7;
    height: 60px;
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 225px;
    cursor: none;
}

.left-nav-bottom.opened {

}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
    bottom: -10px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
    left: 17px;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    left: -0.4rem;
    width: 0.4rem;
    height: 0.8rem;
    top: 50%;
    margin-top: -0.4rem;
}

.tooltip {
    transition: all 0.3s;
    display: none;
    left: 0;
    top: -20px;
    position: absolute;
}

.left-nav .left-nav-btn:hover .tooltip {
    display: block;
}

.left-nav-content .MuiIconButton-root{
    color: #000;
}

.no-threat-detected-card-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 24px;
    padding: 0 24px 0 24px;
    text-align: center;
}

.no-threat-detected-card-wrapper .no-threat-detected-icon {
    width: 88px;
    height: 88px;
    background-color: #E4F4F9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.no-threat-detected-card-wrapper .no-threat-detected-title {
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    color: #193051;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    margin-top: 16px;
}

.no-threat-detected-card-wrapper .no-threat-detected-description {
    color: #212121;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    margin-top: 16px;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}



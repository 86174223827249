.group-select-field-domain{
    width: 100%;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
}
#modal-send-mail .form-body .from-input-mail .MuiGrid-item {
    margin-bottom: 9px;
}

.from-input-mail .Mui-disabled{
    background-color: #EDEDED;
}
footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    min-height: 24px;
    background: #193051;
    color: #fff;
}
footer.fixed {
    position: fixed;
    width: calc(100% - 32px);
    bottom: 0;
    left: 0;
    z-index: 2;
    font-weight: 600;
    font-size: 14px;
}

footer .copyright-sign{
    font-size: 14px;
    font-weight: 500;
}

footer .terms-of-use{
    cursor: pointer;
}

/*Terms of use Modal*/
#terms-of-use .MuiPaper-root{
    width: 1028px;
    max-width: 1028px;
    max-height: 90%;
    margin-top: 70px;
}

#terms-of-use #alert-dialog-title{
    font-family: "Righteous";
    font-size: 36px;
    font-weight: 400;
    text-transform: capitalize;
    color: #193051;
}

#terms-of-use .term-content{
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 150%;
    color: #011111;
    font-weight: 500;
    background: #EDEDED;
    padding: 1px 20px;
    border-radius: 4px;
}
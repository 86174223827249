.inbox-page-details {
    box-sizing: border-box;
}

.mail-urls-viewer {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.inbox-page-details .MuiGrid-root{
    align-content: flex-start;
}


#email-detail .MuiChip-root{
    height: unset;
    margin-left: 5px;
}

#email-detail .Mui-selected .MuiChip-root{
    background: #345C8B;
    color: #fff;
    margin-left: 5px;
}

#email-detail .MuiChip-label{
    padding: 4px 8px;
    line-height: 13px;
}
#email-detail .MuiBox-root {

}

.nodata-content {
    margin: 0 auto
}


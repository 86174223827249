#modal-header-view-detail {
    max-height: 900px;
    min-height: 900px;
}

#modal-header-view-detail .MuiDialogContent-root{
    padding: unset;
}

#modal-header-view-detail .delivery-information-modal-container{
    max-height: 800px;
}

#modal-header-view-detail .delivery-information-modal-container .MuiTabs-flexContainer{
    border-bottom: 1px solid #E0E0E0;
}

#modal-header-view-detail .delivery-information-modal-container .MuiBox-root .MuiBox-root:first-child{
    padding: 8px 0;
}

#modal-header-view-detail .delivery-information-modal-container .MuiButtonBase-root:not(.Mui-selected){
    color: #666666;
}

#modal-header-view-detail .delivery-information-modal-container .MuiChip-root{
    min-height: 23px;
}

#modal-header-view-detail .delivery-information-modal-container .MuiChip-root .MuiChip-label{
    padding: 5px 8px;
    line-height: 13px;
}
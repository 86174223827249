.MuiFormHelperText-root{
    display: none;
}

.MuiFormHelperText-root.Mui-error{
    color: #B00020;
    font-weight: 500;
    display: block;
}

.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #C80000 !important;
}
#left-menu{
    padding-top: 1px;
    padding: 5px;
}

#left-menu.Mui-expanded{
    background: #EBF9FA;
    border-radius: 4px;
    padding: 5px;
}

#left-menu.Mui-expanded > div{
    margin-bottom: 5px !important;
}

#left-menu.Mui-expanded > div:not(.MuiAccordionSummary-root.active):hover,
#left-menu > div:not(.MuiAccordionSummary-root.active):hover{
    background: #CFECF0;
    border-radius: 4px;
}

#left-menu .MuiAccordionSummary-root{
    padding: 0;
    margin: 0;
    min-height: 41px;
}

#left-menu .MuiAccordionSummary-root.active{
    padding: 0;
    border-left: 4px solid #345C8B;
    background: #BDEAED;
    border-radius: 4px;
    margin: 0;
    min-height: 41px;
}

#left-menu .MuiAccordionSummary-content,
#left-menu .MuiAccordionSummary-content p{
    max-width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    width: 90%;
}

#left-menu.Mui-expanded .MuiAccordionSummary-content p,
#left-menu.active .MuiAccordionSummary-content p{
    font-weight: 700;
    color: #345C8B;
    display: inline-flex;
    align-items: center;
    width: 90%;
    padding: 10px 0;
}

#left-menu .MuiAccordionSummary-content svg,
#left-menu.active .MuiAccordionSummary-content svg{
    margin-right: 8px;
    width: 30px;
    margin-left: 8px;
}

#left-menu .active .MuiAccordionSummary-content svg{
    margin-left: 4px;
}

#left-menu > div:not(.MuiAccordionSummary-root.active):hover .MuiAccordionSummary-content svg path,
#left-menu.Mui-expanded .MuiAccordionSummary-content svg path,
#left-menu.active .MuiAccordionSummary-content svg path{
    fill: #345C8B;
}

#left-menu .menu-child{
    padding: 0
}

#left-menu .menu-child .left-nav-btn{
    margin: unset !important;
    padding: 0 !important;
    width: 100% !important;
}

#left-menu .menu-child .left-nav-btn.active{
    border-left: 4px solid #345C8B !important;
}

#left-menu .menu-child .left-nav-btn.active p{
    margin-left: 40px !important;
}

#left-menu .menu-child .left-nav-btn:not(.active) p{
    margin-left: 44px !important;
}
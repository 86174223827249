.summary-analysis-viewer-wrapper {
    position: relative;
    display: flex;
    height: auto;
    font-family: "Montserrat";
    font-size: 14px;
}

.summary-analysis-viewer-wrapper .left-partial {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20);
    padding: 24px;
    width: 352px;
    height: fit-content;
    border-radius: 4px;
    margin-right: 24px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

}

.summary-analysis-viewer-wrapper .left-partial .left-partial-header .title {
    font-family: "Righteous";
    font-size: 24px;
    color: #193051;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-threat-score {
    height: auto;
    margin-bottom: 24px;
    padding-bottom: 24px;
    display: grid;
    justify-content: center;
    border-bottom:  1px solid #E0E0E0;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-threat-score .chart {
    width: 300px;
    height: 150px;
    padding-left: 25px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-threat-score .chart-info {
    padding: 0 36px 0 45px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #808888;
    display: flex;
    max-width: 300px;
    justify-content: space-between;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-threat-score .result-score {
    display: flex;
    justify-content: center;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-threat-score .result-score-explain {
    font-weight: 500;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.60);
    margin-top: 10px;
    text-align: center;
    min-width: 352px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-threat-score .result-score span {
    width: fit-content;
    height: 40px;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 24px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-result {
    height: 128px;
    margin-bottom: 24px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: #F6F6F6;
    padding: 0 24px 0 24px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-result .priority-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    height: 64px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-result .category-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-result .priority-info .priority-value {
    width: fit-content;
    height: 20px;
    background: #DB071E;
    padding: 0 12px 0 12px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-description {
    height: fit-content;
    border-top: 1px solid #E0E0E0;
    padding: 24px 0 24px 0;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-description .description-box {
    display: flex;
    border-radius: 4px;
    height: fit-content;
    margin-top: 16px;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-description .description-box .description-content {
    margin-left: 8px;
    font-weight: 500;
    max-width: 305px;
    word-break: break-word;
    white-space: break-spaces;
    text-overflow: ellipsis;
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-description .description-box .error-icon svg path{
    fill: #B20007
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-description .description-box .info-icon svg path{
    fill: #467599
}

.summary-analysis-viewer-wrapper .left-partial .left-partial-description .description-box .warning-icon svg path{
    fill: #F29100
}

.summary-analysis-viewer-wrapper .right-partial {
    height: auto;
    width: calc(100% - 424px);
    padding: 0 0 24px 0;
}

.summary-analysis-viewer-wrapper .right-partial .no-threat-detected-card-wrapper {
    align-items: normal;
    position: relative;
    top: 80px;
}

.summary-analysis-viewer-wrapper .right-partial .title{
    display: block;
    font-family: "Righteous";
    font-size: 24px;
    color: #193051;
}

.summary-analysis-viewer-wrapper .right-partial .entities-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 3px solid #E0E0E0;
    align-items: center;
}

.summary-analysis-viewer-wrapper .right-partial .view-scan-result-detail {
    cursor: pointer;
    min-width: 30px;
    border-radius: 40px;
}

.summary-analysis-viewer-wrapper .right-partial .entities-content .entities-attribute {
    display: flex;
    margin-top: 16px;
    align-items: flex-start;
}

.summary-analysis-viewer-wrapper .right-partial .entities-content .entities-attribute .entities-attribute-message {
    display: block;
    line-height: 23px;
    position: relative;
    top: 2px;
    word-break: break-word;
    text-overflow: ellipsis;
}

.summary-analysis-viewer-wrapper .right-partial .entities-header .left-entities-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-analysis-viewer-wrapper .right-partial .entities-header .left-entities-header .left-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 40px;
    background-color: #DFF4ED;
    margin-right: 16px;
}

.summary-analysis-viewer-wrapper .right-partial .entities-header .left-entities-header .entities-title {
    font-family: "Montserrat";
    font-size: 20px;
    color: #193051;
    font-weight: 700;
}

.summary-analysis-viewer-wrapper .right-partial .senders-analysis-content,
.summary-analysis-viewer-wrapper .right-partial .urls-analysis-content,
.summary-analysis-viewer-wrapper .right-partial .attachments-analysis-content,
.summary-analysis-viewer-wrapper .right-partial .text-content-analysis-content,
.summary-analysis-viewer-wrapper .right-partial .emails-analysis-content,
.summary-analysis-viewer-wrapper .right-partial .dns-analysis-content,
.summary-analysis-viewer-wrapper .right-partial .ips-analysis-content{
    padding: 24px;
    height: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    margin-top: 24px;
    border-radius: 4px;
}

.summary-analysis-viewer-wrapper .right-partial .urls-analysis-content .add-link-icon {
    cursor: default;
}

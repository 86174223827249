.default-dialog .MuiDialog-paper{
    max-width: 560px;
    height: fit-content;
}

.default-dialog #alert-dialog-title{
    display: flex;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 20px;
    align-items: center;
    color: #212121;
    padding-bottom: 8px;
}

.default-dialog .MuiTypography-root,
.default-dialog .MuiDialogContent-root{
    font-family: 'Montserrat';
    font-style: normal;
    color: #212121;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
}

.default-dialog #alert-dialog-title > svg{
    font-size: 36px;
}

.default-dialog .MuiDialogContent-root .notes{
    border-left: 4px solid #CCCCCC;
    margin-top: 15px;
}

.default-dialog .MuiDialogContent-root .notes div{
    margin-left: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}

.default-dialog .MuiDialogContent-root .notes div span{
    font-weight: 700;
}

.MuiInputLabel-root .MuiInputLabel-asterisk{
    color: #ED1C24;
}

#alert-dialog-action .action-btn button p {
    font-family: 'Metropolis';
}

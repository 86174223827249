.blocklist-entry-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    height: 60px;
    transition: background-color 0.2s;
}
.blocklist-entry-item.selected {
    background: #E7EBF0;
}

.blocklist-attribute-title {
    color: #212121;
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 28.8px */
}
.blocklist-entries {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.entry-checkbox{
    width: 50px;
    text-align: center;
}

 .entry-icon {
     color: darkslategrey;
     line-height: 100%;
     margin-right: 16px
 }

.blocklist-entry-item.disabled {
    border-left: 0;
    border-right: 0;
}
 .blocklist-entry-item.disabled .entry-icon {
    width: 50px;
     text-align: center;
     margin-right: 0;
}

.blocklist-entry-item.disabled .blocklist-entry-start {
    width: 40px;
    flex-basis: 40px;
}

.blocklist-entry-item .blocklist-entry-start {
    flex-basis: 90px;
    display: flex;
    align-items: center;
}

.blocklist-entry-item .blocklist-entry-end {
    flex: 1;
    min-width: 0;
}

.blocklist-entry-end-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blocklist-invalid-entry-wrapper .blocklist-entries {
    gap: 0;
}

.blocklist-invalid-entry-wrapper .blocklist-attribute-title{
    margin-bottom: 16px;
}


.entry-value {
    overflow: hidden;
    color: #011111;
    text-overflow: ellipsis;
    word-break: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
}

.entry-value {
    flex-grow: 1;
}
.entry-expire-date {
    padding-left: 8px;
    padding-right: 8px;
    width: 360px;
}

.entry-expire-date-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.MuiChip-root.blocklist-chip {
    padding: 0px;
    height: 32px;
    border-radius: 24px;
}

.MuiChip-root.blocklist-chip.selected {
    background: #DFF4ED;
    border-color: #467599;
}

.MuiChip-root.blocklist-chip.selected .MuiChip-label {
    padding-right: 12px;
}

.MuiChip-root.blocklist-chip .MuiChip-label {
    font-size: 14px;
    padding: 8px;
    color: #011111;
}

.blocklist-valid-entry-wrapper .MuiToggleButtonGroup-root {

}

.MuiTypography-root.label-small {
    font-size: small;
    font-weight: 600;
}

.blocklist-table {
    table-layout: fixed;
    width: 100%;
}
.blocklist-table tr,.blocklist-table td {
    padding: 0;
}
.blocklist-table-expire-cell{
    width: 370px;
}

.blocklist-table-invalid-cell {
    text-align: right;
    width: 100px;
}

.blocklist-entry-status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 700;
}

.blocklist-entry-status.error{
    color: #C80000;
}
.blocklist-entry-status.existing{
    color: #808888;
}
@media screen and (max-width: 640px) {

    .page-content-title .page-title {
        margin-top: 0;
    }

    .inbox-page .page-content-title{
        display: flex;
        flex-direction: column;
    }

    .dropdown-container:last-child {
        border: none;
        background: transparent;
    }

    .page-content-body {
        padding: 0;
        border-top: 1px solid #E0E0E0;
    }

    .inbox-content {
        border: none;
        border-radius: 0;
    }

    .search-filter .text-input-style {
        background: #EDEDED;
        border: 1px solid #E0E0E0;
        height: 40px!important;
    }

    .search-filter .text-input-style:focus {
        border: 1px solid #666666;
    }

    .more-filter {
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-left: 10px;
    }

    .more-filter.hide-icon img {
        margin-top: 12px!important;
    }

    .search-filter {
        width: calc(100% - 54px);
    }

    .search-inbox .search-menu-right.hide-filter {

    }

    .search-inbox .search-menu-right.show-filter {
        display: block;
    }

    .search-filter .text-input-style {
        width: 100%!important;
    }

    .icon-dropdown-header .item ul li.full-name {
        border-bottom: 1px solid #E0E0E0;
        color: #011111;
        font-weight: 700;
    }

    .search-menu {
        padding: 0;
    }

    .search-inbox {
        background: transparent;
        padding: 0;
    }

    header svg path {
        fill: #fff;
    }

    header li svg path {
        fill: #345C8B;
    }

    .date-range-filter .daterange-input{
        border: unset;
        padding: unset;
    }

    .date-range-filter .date-label{
        background-color: unset;
        padding-left: unset;
        color: #909090;
    }

    .date-range-filter .date-label .date-value{
        font-weight: 500;
        color: #011111;
    }

    .date-range-filter .date-label svg{
        margin-left: 15px;
    }

    .date-range-filter .date-label svg path{
        fill: #345C8B;
    }

    .search-inbox .search-menu-right{
        flex-wrap: wrap;
        justify-content: left !important;
    }

    .inbox-datatable .MuiDataGrid-columnHeaders{
        display: none;
    }

    .MuiDataGrid-row .checkbox-col {
        align-items: flex-start !important;
    }

    .MuiDataGrid-row > .MuiDataGrid-cell{
        padding: unset !important;
    }

    .MuiDataGrid-cell .info-mobile-row{
        max-width: 100%;
        flex-grow: 1;
    }

    .info-mobile-row .priority{
        min-width: 98px;
        height: 100%;
        display: flex;
        justify-content: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
    }

    .info-mobile-row .priority.High{
        background: #ED1C24;
    }
    .info-mobile-row .priority.High:hover{
        background: #B20007;
    }

    .info-mobile-row .priority.Medium{
        background: #FCAB10;
    }
    .info-mobile-row .priority.Medium:hover{
        background: #D88E00;
    }

    .info-mobile-row .priority.Low{
        background: #03A9F5;
    }
    .info-mobile-row .priority.Low:hover{
        background: #007AB2;
    }

    .info-mobile-row .priority.Unknown{
        background: #909090;
    }
    .info-mobile-row .priority.Unknown:hover{
        background: #5A5A5A;
    }

    .info-mobile-row .datetime{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #909090;
    }

    .MuiDataGrid-cell .row{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .MuiDataGrid-cell .row > b{
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }

    .MuiDataGrid-cell .row .subject{
        max-width: 220px;
        word-break: break-word;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .MuiDataGrid-cell .row.third-row{
        justify-content: flex-start;
    }

    .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone {
        max-height: none !important;
        max-width: fit-content!important;
    }

    .MuiDataGrid-row .email-content{
        padding-right: 10px !important;
    }

    .MuiDataGrid-virtualScrollerRenderZone, .MuiDataGrid-row, .MuiDataGrid-row .email-content {
        width: 100% !important;
        max-height: none !important;
        max-width: 100% !important;
        flex-grow: 1;
    }

    .daterangepicker .ranges ul {
        overflow: auto;
        white-space: nowrap;
    }

    .daterangepicker .ranges ul li {
        display: inline-block;
    }

    .inbox-datatable .MuiDataGrid-virtualScroller {
        margin-top: 15px!important;
        /*height: calc(100vh + 450px)!important*/
    }

    .checkbox-col:before {
        height: 100%;
    }

    .filter-title-mb {
        font-weight: 700;
        font-size: 16px;
        color: #193051;
    }

    .mobile-popup {
        position: fixed;
        background: #fff;
        width: 100%!important;
        left: 0;
        top: 100%;
        height: calc(100% - 58px);
        z-index: 2;
        transition: all 0.5s;
    }

    .mobile-popup.show-filter {
        top: 58px;
    }
    .mobile-popup.show-filter#actions-dropdown-inbox {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        top: 50%
    }

    .MuiPopover-root .MuiPaper-root{
        width: 100%;
    }

    .mobile-popup .filter-title-mb {
        text-align: center;
        border-bottom: 1px solid #E0E0E0;
        padding-top: 16px;
        padding-bottom: 16px;
        position: relative;
    }

    .mobile-popup .filter-title-mb .image-style {
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .mobile-popup.search-menu-right .MuiFormControl-root{
        width: calc(100% - 32px);
        margin: 16px;
    }
    .mobile-popup .MuiOutlinedInput-root {
      height: 48px;
    }

    .mobile-popup .bottom-buttons-popup .reset-btn {
        border: 1px solid #E0E0E0!important;
        border-radius: 4px;
        color: #345C8B!important;
        width: auto!important;
    }

    .mobile-popup .bottom-buttons-popup {
        box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06);
        position: absolute;
        bottom: 0;
        padding: 16px;
        width: calc(100% - 32px);
        display: flex;
        justify-content: end;
    }

    .mobile-popup .btn-done {
        text-align: center;
        padding: 0;
        border-radius: 4px;
    }
    .mobile-popup .btn-done p{
        font-weight: 600;
        margin: 0 auto!important;
    }

    .toolbar{
        height: 0;
    }

    .inbox-datatable .toolbar .left-toolbar{
        position: fixed;
        background: #FFFFFF;
        box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06);
        height: 96px;
        width: calc(100% - 32px);
        bottom: 0;
        padding: 0 16px;
        left: 0;
        z-index: 2;
    }

    .inbox-datatable .toolbar .left-toolbar .actions-dropdown-inbox{
        margin-left: auto;
        background: #345C8B!important;
        height: 48px!important;
        border: unset;
    }

    .inbox-datatable .toolbar .left-toolbar .actions-dropdown-inbox .selected-title {
        color: #fff!important;
        height: 48px;
    }

    .inbox-datatable .toolbar .left-toolbar .actions-dropdown-inbox .selected-title:after {
        background: url('../../assets/images/white_arrow_drop_down.svg') no-repeat center;
    }

    .inbox-datatable .toolbar .left-toolbar.hide-left-toolbar {
        display: none;
    }
}

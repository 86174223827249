.message-dialog .MuiPaper-elevation {
    width: 560px;
}

.message-dialog #message-title {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 0;
}

.message-dialog #message-title > span {
    width: 420px;
    line-height: 1.4;
}

.message-dialog .convert-message-content {
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    text-align: left;
}
#modal-new-block-list .MuiDialog-paper {
    width: 1237px;
    max-width: 867px;
    height: auto;
    max-height: 90%;
}

#modal-new-block-list .MuiDialogContent-root {
    background: #F1F1F1;
}

#modal-new-block-list .MuiDialogContent-root {
    padding: 24px 40px 0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}

.blocklist-header, .blocklist-valid-entry-wrapper, .blocklist-invalid-entry-wrapper {
    margin-bottom: 32px;
}

.blocklist-header, .blocklist-valid-entry-wrapper {

}

.blocklist-valid-entry-wrapper {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    gap: 16px;
}

.blocklist-invalid-entry-wrapper {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: rgba(255, 255, 255, 0.50);
    gap: 16px;
}

.blocklist-valid-title, .blocklist-invalid-title {
    color: #008545;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    display: flex;
    align-items: center;
    gap: 16px;
}
.blocklist-invalid-title {
    color: #C80000;
}


.select-container {
    width: 244px
}

#modal-convert-to-template #alert-dialog-title{
    align-items: center;
    color: #193051;
    border-bottom: 1px solid #E0E0E0;
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 24px;
}

#modal-convert-to-template .label-input{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #212121;
    width: fit-content;
    margin-bottom: 4px;
}

#modal-convert-to-template .MuiDialogContent-root {
    padding: 24px;
    border-bottom: 1px solid #E0E0E0;
}

#modal-convert-to-template #alert-dialog-action {
    padding-top: 11px;
    padding-bottom: 11px;
}
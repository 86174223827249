.number-circle {
    width: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    border: 1px solid #212121;
    display: inline-block;
    margin-right: 8px;
}

.number-title {
    display: flex;
    align-items: center;
    margin: 0 0 24px 0;
    font-size: 16px;
    font-weight: 600;
}
.sub-number-title {
    margin: 0 0 24px 0;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.box-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
}

.rule-box {
    background-color: #fff;
    margin: 16px 16px 0 16px;
    padding: 24px;
}
.rule-box .input-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 4px;
}

.rule-box .input-field {
    /*margin-bottom: 24px;*/
    max-width: 666px;
    font-size: 14px;
    font-weight: 500;
}
.rule-box .switch-label-active .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 600;
    color: #008545;
}

.rule-box .switch-label-inactive .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 600;
    color: #CCCCCC;
}

.rule-box .sub-box-swapper .input-select-field {
    margin-bottom: 24px;
    max-width: 400px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
}
.rule-box .sub-box-swapper {
    margin-left: 28px;
    margin-bottom: 16px;
}

.rule-box .sub-box-swapper.box-send-mail {
    padding: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    max-width: 646px;
}
.rule-box .sub-box-swapper .box-send-mail-description {
    margin-left: 32px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    max-width: 353px;
}

.rule-box .sub-box-swapper.checked-box {
    background-color: #F9F9F9;
}
.rule-box .sub-box-swapper .group {
    max-width: 350px;
    margin-bottom: 16px;
}
.rule-box .sub-box-swapper .group .MuiFormControlLabel-label{
    font-size: 14px;
    font-weight: 500;
}
.rule-box .sub-box-swapper .MuiFormControlLabel-label{
    font-size: 14px;
    font-weight: 500;
    color: #011111;
}

.rule-box .sub-box-swapper .group .input-label-select{
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #011111;
}

.rule-box .sub-box-swapper .group .group-select-field{
    width: 240px;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
}

.rule-box .sub-box-swapper .Mui-disabled{
    background-color: #EDEDED;
}

.rule-box.mg-bottom {
    margin-bottom: 96px;
}

#modal-send-mail.btn-right #alert-dialog-action {
    justify-content: end;
}

#modal-send-mail.btn-right {
    z-index: 101;
}

.rule-box .sub-box-swapper .group .group-select-field .checked-icon {
    display: none;
}

.MuiMenu-list .checked-icon{
    display: none;
}

.MuiMenu-list .Mui-selected .checked-icon{
    display: flex;
    margin-left: auto;
    color: #193051;
}
.sub-box-swapper.box-send-mail .Mui-checked {
    color: #467599;
}
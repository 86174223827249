.inbox-detail-activity-wrapper .MuiBox-root {
    padding: 0 0 128px;
}

.inbox-detail-activity-wrapper .datatable-inbox .MuiIconButton-root:hover {
    background-color: #D7E2EE;
    border-radius: 50%;
}

.inbox-detail-activity-wrapper .datatable-inbox .MuiDataGrid-root .MuiDataGrid-main{
    border-top: 2px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    border-left: 0;
    border-right: 0;
}

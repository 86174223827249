.page-content {
    display: flex;
    padding-top: 58px;
}
.right-content {
    width: calc(100% - 225px);
}

.statistic-report-content {
}

.statistic-section-group {
    padding: 32px;
}

.dashboard-wrapper {
    box-sizing: border-box;
    margin-bottom: 80px;

}

.apexcharts-data-labels {
    text-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
}

.date-label-dashboard {
    border:1px solid #E6E7E7;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.date-label-dashboard.active {
    background-color: #9FD8DB;
}

.date-label-dashboard:hover {
    background-color: #EBF9FA;
}
.date-range-filter{
    width: 320px;
    margin: 8px 0;
    position: relative;
}

.date-range-filter .date-label{
    background-color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    color: #193051;
    font-weight: 700;
    top: 1px;
    font-size: 14px;
    left: 1px;
    z-index: 0;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    width: calc(320px - 12px);
}

.date-range-filter .date-label .date-value{
    font-weight: 500;
    margin-left: 5px;
    min-width: 180px;
}

.date-range-filter .date-label svg{
    margin-bottom: 2px;
}

.date-range-filter .date-range-title{
    min-width: 81px;
    margin-bottom: 8px;

}

/*.date-range-filter .daterange-input{*/
/*    padding: 5px 10px;*/
/*    border: 1px solid #B7B7B7;*/
/*    border-radius: 5px;*/
/*}*/

.date-range-filter .daterange-input{
    z-index: 1;
    background: transparent;
    position: relative;
    color: #193051;
    align-self: center;
    height: 36px;
    padding: 0 2.3rem 0 0.75rem;
    outline: none;
    font-size: 1rem;
    border: 1px solid #CCCFCF;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Montserrat", serif;
    text-indent: -9999999px;
    width: calc(320px - 2.3rem - 0.75rem);
}


.date-range-filter .daterange-input.without-icon{
    background: unset;
    width: 210px;
    align-self: center;
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
    padding: 3px;
}

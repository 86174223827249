.page-content-header {
    padding: 16px;
    background: white;
    box-shadow: 0 0 4px #ccc;
}

.page-title .info-action{
    margin-left: 10px;
}

.page-content-title {
    margin-top: 16px;
}

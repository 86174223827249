.grid-container {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    padding-left: 30px;
}
.grid-container .grid-item {
    margin: 0 10px 0;
}

textarea {
    resize: none;
}
.grid-item button {
    justify-content: center;
}

.action-container button{
    pointer-events: none;
}

.summary-reported {
    display: flex;
}

.dropdown-list{
    margin-right: unset !important;
}

#action-mail hr{
    width: 219px;
    margin: 0 auto;
    border: 1px solid #E0E0E0;
}

.dropdownlist-inbox-action .MuiList-root.Priority\:{
    width: 98px;
}

.dropdownlist-inbox-action .MuiList-root.Priority\: .MuiListItem-root{
    display: flex;
    justify-content: center;
}

.dropdownlist-inbox-action .MuiList-root .MuiListItem-root.archived{
    padding: 0 16px;
    display: flex;
    justify-content: center;
}

.MuiList-root.Category{
    background-color: #EDEDED;
}

.MuiList-root.Category p, .MuiList-root.Status p{
    font-weight: 600;
}


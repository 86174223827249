.logout-wrapper {
    position: relative;
    background-color: #FFFFFF;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logout-wrapper .logo-logout-left {
    margin: 40px 0 0 44px !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 200px !important;
}

.logout-wrapper .is-mobile .logo-logout-left {
    position: fixed;
    top: 0;
    left: 0;
    margin: 32px 0 0 36px;
    width: 160px;
}

.logout-wrapper .mobile-only .logo-logout-left {
    position: fixed;
    top: 0;
    left: 0;
    margin: 32px 0 0 36px;
    width: 120px;
}

.center-logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    box-sizing: border-box;
    justify-content: center;
}

.logout-logo {
    background-color: #E4F4F9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-icon path {
    fill: #467599;
}

.link-logout {
    cursor: pointer;
    color: #467599;
}

.is-mobile .link-logout  {
    font-size: 14px !important;
}

.mobile-only .link-logout  {
    font-size: 12px !important;
}

.link-logout {
    font-size: 16px !important;
}

.logout-wrapper .log-out-text-title {
    text-align: center !important;
    font-size: 24px !important;
    color: #212121;
    width: 100%;
    font-weight: 700;
    margin: 40px 0 24px 0 !important;
}

.logout-wrapper .is-mobile .log-out-text-title {
    text-align: center !important;
    font-size: 20px !important;
    color: #212121;
    font-weight: 700;
    width: 100%;
    margin: 32px 0 16px 0 !important;
}

.logout-wrapper .mobile-only .log-out-text-title {
    text-align: center !important;
    font-size: 18px !important;
    color: #212121;
    font-weight: 700;
    width: 100%;
    margin: 32px 0 16px 0 !important;
}
/*Logout Logo*/
.logout-wrapper .is-mobile .logout-logo{
    width: 78px;
    height: 78px;
}

.logout-wrapper .mobile-only .logout-logo{
    width: 68px;
    height: 68px;
}

.logout-wrapper .logout-logo{
    width: 88px;
    height: 88px;
}

/*Center Logout*/
.link-logout .is-mobile .center-logout{
    padding: 0 20px;
}

.link-logout .mobile-only .center-logout{
    padding: 0 14px;
}

.link-logout .center-logout{
    padding: 0 26px;
}

.logout-wrapper .log-out-text {
    text-align: center !important;
    font-size: 16px !important;
    color: #212121;
    width: 100%;
}

.logout-wrapper .is-mobile .log-out-text {
    text-align: center !important;
    font-size: 14px !important;
    color: #212121;
    width: 100%;
}

.logout-wrapper .mobile-only .log-out-text {
    text-align: center !important;
    font-size: 12px !important;
    color: #212121;
    width: 100%;
}

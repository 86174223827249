.app-vertical-line {
    font-size: 24px;
    color: #CCCFCF;
}

.app-flex-horizontal {
    display: flex;
    align-items: center;
    gap: 24px;
}

.app-flex-horizontal.flex-space-between {
    justify-content: space-between;
}

.app-flex-gap-1 {
    gap: 12px;
}

.app-color-brand {
    color: #345C8B;
    font-weight: 600 !important;
}

.app-box-shadow-md {
    border-radius: var(--radius-radius-rounded, 24px);
    border: 1px solid var(--additional-shade-plain-black-200, #CCCFCF);
    background: var(--brand-colours-primary-seafoam-white, #FFF);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
}

.MuiTypography-root {
    line-height: 160%;
}
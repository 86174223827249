#base-domains-container{
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 24px;
}

#base-domains-container .base-domain-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#base-domains-container .base-domain-header .title-domain span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    color: #212121;
    margin-right: 10px;
}

#base-domains-container .base-domain-content{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #212121;
}

#modal-verify-sender-email .MuiDialogContent-root,
#modal-verify-email-successfully .MuiDialogContent-root{
    padding: 10px 24px 0;
}

#modal-verify-email-successfully .MuiDialogActions-root .button.success:hover {
    background-color: #193051;
}

#modal-verify-email-successfully .MuiDialogContent-root .send-test-email-link {
    color: #467599;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
}

.label-small {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #666666;
}

.no-data-card-container{
    display: flex;
    justify-content: center;
    align-content: center;
}

.no-data-card-title{
    display: block;
    justify-content: start;
    align-content: center;
}

.no-data-card-border-box{
    box-sizing: border-box;
}